<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{
                    path: '/examination_Management/individual_examination_management/examination_home_manage/examination_home_manage',
                }"
                >个人赛考务管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>免初赛管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">信息筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="100px"
                ref="queryFormRef"
            >
                <el-row :gutter="1">
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择赛季"
                                style="width: 100%"
                                v-model="queryForm.season"
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="手机号" prop="phone">
                            <el-input
                                v-model="queryForm.phone"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="学生姓名" prop="name">
                            <el-input
                                v-model="queryForm.name"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="学校名称" prop="schoolName">
                            <el-input
                                v-model="queryForm.schoolName"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否匹配" prop="status">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.status"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in isMatch"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="getDataList('search')"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="primary"
                        plain
                        style="width: 100%"
                        @click="reset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <!-- 信息列表 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="9">
                    <span class="title_class">成绩信息列表</span>
                </el-col>
                <el-col :span="15">
                    <div style="text-align: right">
                        <el-button
                            type="primary"
                            @click="importScoreDialogVisible = true"
                            >批量导入成绩</el-button
                        >
                        <el-button
            type="primary"
            plain
            @click="downloadInfoList"
            icon="el-icon-download"
            >批量导出</el-button
          >
                    </div>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column label="赛季" prop="season"> </el-table-column>
                <el-table-column
                    label="学校"
                    prop="schoolName"
                ></el-table-column>
                <el-table-column label="姓名" prop="name"></el-table-column>
                <el-table-column label="手机号" prop="phone"></el-table-column>
                <el-table-column label="是否匹配" prop="status">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.status === true"
                            size="mini"
                            >已匹配</el-tag
                        >
                        <el-tag type="danger" v-else size="mini">未匹配</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180px">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="small"
                            @click="showDeleteDialog(scope.row)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 批量导入线下成绩 -->
        <el-dialog
            title="批量导入免初赛"
            :visible.sync="importScoreDialogVisible"
            @close="importScoreDialogClose"
            width="30%"
        >
            <span slot="footer" class="dialog-footer">
                <el-upload
                    :action="scoreImportInfo.uploadUrl"
                    ref="scoreImportInfoRef"
                    :headers="scoreImportInfo.headerObj"
                    :on-progress="scoreImportInfoProgress"
                    :on-success="scoreImportInfoSuccess"
                    :on-error="scoreImportInfoError"
                    :on-exceed="scoreImportInfoExceed"
                    :limit="1"
                    :auto-upload="false"
                    :on-change="onChangeLoad"
                >
                    <el-button slot="trigger" size="small" type="primary"
                        >选取文件</el-button
                    >
                    <el-button
                        style="margin-left: 10px"
                        type="primary"
                        size="small"
                        @click="downLoadscoreImportInfoStencil"
                        >下载模版</el-button
                    >
                    <el-button
                        style="margin-left: 10px"
                        size="small"
                        type="success"
                        @click="submitscoreImportInfo"
                        >新增选手信息</el-button
                    >
                </el-upload>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getAllAreaList, getSchoolListWithCity, enrollmentAvoidUserList, deleteImportUser } from '@/http/api'
export default {
  data () {
    return {
      scoreImportInfo: {
        uploadUrl: this.$env.baseIP + 'enrollmentAvoidUser/excelImport',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      total: 0,
      dataList: [],
      importScoreDialogVisible: false,
      areasData: [],
      provincesSchoolList: [],
      isMatch: this.$userInfo.isMatch(),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      queryForm: {
        season: '',
        phone: '',
        name: '',
        schoolName: '',
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      isUploadFile: false // 是否已选择上传文件
    }
  },
  created () {
    this.getAllAreaList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 重置搜索条件
    reset () {
      this.queryForm.pageNum = 1
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    getDataList (type) {
      if (type === 'search') {
        this.queryForm.pageNum = 1
      }
      enrollmentAvoidUserList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
        this.getDataList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      if (!value) return
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 下载模板
    async downLoadscoreImportInfoStencil () {
      var downLoadUrl = this.$env.baseIP + 'enrollmentAvoidUser/downLoadTemplate'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithFile(this, downLoadUrl, {})
    },
    // 监听上传文件弹窗关闭事件
    importScoreDialogClose () {
      // this.$refs.editInfoFormRef.clearFiles()
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.isUploadFile = false
    },
    // 上传成绩加载
    scoreImportInfoProgress () {
    },
    // 新增成绩
    submitscoreImportInfo () {
      this.$nextTick(function () {
        if (this.isUploadFile) {
          this.$refs.scoreImportInfoRef.submit()
        } else {
          this.$message.error('请先选择要上传的文件')
        }
      })
    },
    // 选择文件修改状态
    onChangeLoad (files, fileList) {
      if (fileList.length > 0) {
        this.isUploadFile = true
      }
    },
    // 批量上传成功
    scoreImportInfoSuccess (res) {
      this.$curUtils.closeLoading(this)
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.isUploadFile = false
      if (res.code !== 200) {
        return this.$alert('请修改后再上传，失败原因:' + '<br/>' + res.msg, '上传失败', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      }
      this.$message.success('批量上传报名信息成功')
      this.getDataList('search')
      this.importScoreDialogVisible = false
    },
    scoreImportInfoError (error, file, fileList) {
      console.log('batchImportInfoError', error, file, fileList)
      this.$curUtils.closeLoading()
      this.$message.error(error)
    },
    scoreImportInfoExceed (files, fileList) {
      console.log('fileList', files, fileList)
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.$refs.scoreImportInfoRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    // 删除信息
    showDeleteDialog (item) {
      this.$confirm('是否删除该信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('这是走的确定')
        // 确认删除
        this.confirmDelectInfo(item)
      }).catch((res) => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 确认删除信息
    confirmDelectInfo (item) {
      deleteImportUser({ id: item.id }).then((res) => {
        this.$message.success('删除成功')
        this.getDataList('search')
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    //  导出成绩
    downloadInfoList () {
      var downLoadUrl = this.$env.baseIP + 'enrollmentAvoidUser/export'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, this.queryForm)
    },
    gradeGroupFormatter (data) {
      var gradeData = this.$userInfo.getUserGroupWithGrade(data.scoreGrade)
      return gradeData.userGradeStr
    },
    gradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (data.scoreGrade) {
            if (element.dictValue === data.scoreGrade + '') {
              temp = element.dictLabel
            }
          }
          if (data.grade) {
            if (element.dictValue === data.grade + '') {
              temp = element.dictLabel
            }
          }
        }
      }
      return temp
    }
  }
}
</script>

<style>
</style>
